import { ROOM_TYPE_VOICE_MODE, ROOM_TYPE_FILE_MODE, ROOM_TYPE_DEFAULT, STATE_EVENT_ROOM_TYPE, ROOM_TYPE_CHANNEL } from "../plugins/utils";

export default {
  data() {
    return {
      roomDisplayType: ROOM_TYPE_DEFAULT,
    };
  },
  mounted() {
    this.$matrix.on("Room.timeline", this.onRoomTypeMixinEvent);
  },
  destroyed() {
    this.$matrix.off("Room.timeline", this.onRoomTypeMixinEvent);
  },
  watch: {
    room: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.onRoomTypeMixinTypeEvent(newVal.currentState.getStateEvents(STATE_EVENT_ROOM_TYPE, "") || newVal.currentState.getStateEvents("m.room.create", ""));
        } else {
          this.roomDisplayType = ROOM_TYPE_DEFAULT;
        }
      },
    },
  },
  methods: {
    onRoomTypeMixinEvent(e) {
      if (this.room && this.room.roomId == e.getRoomId() && e && e.getType() == STATE_EVENT_ROOM_TYPE) {
        this.onRoomTypeMixinTypeEvent(e);
      }
    },
    onRoomTypeMixinTypeEvent(e) {
      if (e) {
        const roomType = e.getContent().type;
        // Validate value, or return default
        if ([ROOM_TYPE_FILE_MODE, ROOM_TYPE_VOICE_MODE, ROOM_TYPE_CHANNEL].includes(roomType)) {
          this.roomDisplayType = roomType;
        } else {
          this.roomDisplayType = ROOM_TYPE_DEFAULT;
        }
      }
    },
  },
  computed: {
    availableRoomTypes() {
      let types = [{ title: this.$t("room_info.room_type_default"), description: "", value: ROOM_TYPE_DEFAULT }];
      if (this.$config.experimental_voice_mode) {
        types.push({
          title: this.$t("room_info.voice_mode"),
          description: this.$t("room_info.voice_mode_info"),
          value: ROOM_TYPE_VOICE_MODE,
        });
      }
      if (this.$config.experimental_file_mode) {
        types.push({
          title: this.$t("room_info.file_mode"),
          description: this.$t("room_info.file_mode_info"),
          value: ROOM_TYPE_FILE_MODE,
        });
      }
      return types;
    },
  },
};
