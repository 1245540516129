<template>
  <message-outgoing v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
    <a style="text-decoration: none;color: currentColor" class="bubble" target="_blank" :href="href" >
      <div :class="{ 'thumbnail-item': true, 'preview': true, 'file-item': true }">
        <b>{{ $sanitize(fileName) }}</b>
        <div>{{ fileSize }}</div>
      </div>
    </a>
  </message-outgoing>
</template>

<script>
import exportedAttachmentMixin from "./exportedAttachmentMixin";
import MessageOutgoing from "../MessageOutgoing.vue";

export default {
  name: "MessageOutgoingFileExport",
  extends: MessageOutgoing,
  mixins: [exportedAttachmentMixin],
  components: { MessageOutgoing },
  data() {
    return {
      href: ""
    } 
  }
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";

.thumbnail-item {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.file-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
    flex-direction: column;
    padding: 20px;
    .v-icon {
        margin-bottom: 10px;
        color: currentColor;
    }
}
</style>
