import util from "../../plugins/utils";

export default {
  data() {
    return {
      src: null,
      downloadProgress: null,
      userInitiatedDownloadsOnly: false,
    }
  },
  watch: {
    event: {
      immediate: false,
      handler(value, ignoredOldValue) {
        this.loadAttachmentSource(value);
      }
    }
  },
  mounted() {
    this.loadAttachmentSource(this.event);
  },
  beforeDestroy() {
    this.loadAttachmentSource(null); // Release
  },
  computed: {
    fileName() {
      return util.getFileName(this.event);
    },
    fileSize() {
      return util.getFileSizeFormatted(this.event);
    }
  },
  methods: {
    loadAttachmentSource(event, userInitiated = false) {
      if (this.src) {
        const objectUrl = this.src;
        this.src = null;
        URL.revokeObjectURL(objectUrl);
      }
      if (event) {
        const fileSize = util.getFileSize(event);
        if (!userInitiated && (fileSize == 0 || fileSize > this.$config.maxSizeAutoDownloads)) {
          this.userInitiatedDownloadsOnly = true;
          return;
        }
        util
        .getAttachment(this.$matrix.matrixClient, event, (progress) => {
          this.downloadProgress = progress;
          console.log("Progress: " + progress);
        })
        .then((url) => {
          this.src = url;
        })
        .catch((err) => {
          console.log("Failed to fetch attachment: ", err);
        });
  
      }
    }
  }
}