import util from "../../../plugins/utils";

export default {
  data() {
    return {
      src: null,
    }
  },
  computed: {
    fileName() {
      return util.getFileName(this.event);
    },
    fileSize() {
      return util.getFileSizeFormatted(this.event);
    }
  },
}