<template>
  <v-dialog
    class="ma-0 pa-0"
    v-model="isUnSupportedBrowser"
    persistent
    :width="$vuetify.breakpoint.smAndUp ? '50%' : '90%'"
  >
    <div class="dialog-content text-center">
      <h2 class="dialog-title">{{ $t("global.different_browser_title") }}</h2>
      <div class="dialog-text">{{ $t("global.different_browser_content") }}</div>
      <v-card-actions class="pb-0">
        <v-spacer></v-spacer>
        <v-btn
          :color="locationUrlCopied ? '#DEE6FF' : 'black'"
          depressed
          @click.stop="copyRoomLink1"
          :class="{'filled-button' : true, 'link-copied-in-place' : locationUrlCopied}"
          >{{ $t(`room_info.${locationUrlCopied ? 'link_copied' : 'copy_link'}`) }}</v-btn
        >
      </v-card-actions>
    </div>
  </v-dialog>
</template>

<script>
const UNSUPPORTED_USER_AGENT = [
  'MicroMessenger' // WeChat
]

export default {
  data () {
    return {
      locationUrlCopied: false,
      locationUrl: window.location.href
    }
  },
  computed: {
    isUnSupportedBrowser() {
      return UNSUPPORTED_USER_AGENT.some((userAgent) => window.navigator.userAgent.includes(userAgent));
    }
  },
  methods: {
    copyRoomLink1() {
      if(this.locationUrlCopied) return
      navigator.clipboard.writeText(this.locationUrl)
      this.locationUrlCopied = true;
      setInterval(() => {
        this.locationUrlCopied = false;
      }, 3000);
    }
  }
}
</script>