<template>
  <div class="create-room fill-height">
    <div>
      <v-container fluid>
        <div class="room-name no-upper">{{ $t("new_room.new_room") }}</div>
        <v-btn id="btn-back" text class="header-button-left" v-show="$navigation && $navigation.canPop()"
          @click.stop="goBack" :disabled="step > steps.NAME_SET">
          <v-icon>arrow_back</v-icon>
          <span class="d-none d-sm-block">{{ $t("menu.back") }}</span>
        </v-btn>
        <!-- <v-btn
              text
              :disabled="
                !roomName || (step != steps.INITIAL && step != steps.CREATED)
              "
              class="header-button-right"
              @click.stop="onCreate"
            >
              <span>{{
                step == steps.CREATED ? $t("new_room.done") : $t("new_room.next")
              }}</span>
            </v-btn> -->
      </v-container>
    </div>

    <v-container fluid class="mt-40">
      <v-row align="center">
        <v-col align="center">
          <v-avatar size="50" color="#ededed" @click.stop="showAvatarPicker">
            <v-img v-if="roomAvatar" :src="roomAvatar" />
            <v-icon v-else>camera_alt</v-icon>
          </v-avatar>
        </v-col>
      </v-row>
      <v-row cols="12" align="center" justify="center">
        <v-col sm="8" align="center">
          <div class="text-start font-weight-light">{{ $t("new_room.name_room") }}</div>
          <v-text-field v-model="roomName" color="black" :rules="roomNamerules" counter="50" maxlength="50"
            background-color="white" v-on:keyup.enter="$refs.topic.focus()" :disabled="step > steps.INITIAL" autofocus
            solo @update:error="updateErrorState"></v-text-field>
          <div class="text-start font-weight-light" v-show="roomName.length > 0">{{ $t("new_room.room_topic") }}</div>
          <v-textarea v-model="roomTopic" v-show="roomName.length > 0" ref="topic" color="black" background-color="white"
            v-on:keydown.enter.prevent="
                () => {
                  $refs.create.$el.focus()
                }
              " :disabled="step > steps.INITIAL" solo full-width auto-grow rows="1" no-resize hide-details></v-textarea>

          <!-- Check if we have any options enabled in config -->
          <template v-if="$config.experimental_voice_mode || $config.experimental_read_only_room || $config.experimental_public_room || $config.experimental_file_mode">
            <div @click.stop="showOptions = !showOptions" v-show="roomName.length > 0" class="options clickable">
              <div>{{ $t("new_room.options") }}</div>
              <v-icon v-if="!showOptions">expand_more</v-icon>
              <v-icon v-else>expand_less</v-icon>
            </div>
            <v-card v-if="$config.experimental_public_room" v-show="showOptions" class="room-option account ma-0" flat>
              <v-card-text class="with-right-label">
                <div>
                  <div class="option-title">{{ $t('room_info.make_public') }}</div>
                  <!-- <div class="option-text">{{ $t('room_info.read_only_room_info') }}</div> -->
                </div>
                <v-switch v-model="unencryptedRoom"></v-switch>
              </v-card-text>
              <div class="option-warning" v-if="unencryptedRoom"><v-icon size="18">$vuetify.icons.ic_warning</v-icon>{{ $t("room_info.make_public_warning")}}</div>
            </v-card>

            <v-card v-if="availableRoomTypes.length > 1" v-show="showOptions" class="room-option account ma-0" flat>
              <v-card-text>
                <div class="d-flex flex-wrap text-left">
                  <div class="col-12 col-md-6 mr-auto pa-0">
                    <div class="option-title">{{ $t('room_info.room_type') }}</div>
                  </div>
                  <div class="col-12 col-md-6 pa-0">
                    <RoomTypeSelector v-model="roomType" />
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <v-card v-if="$config.experimental_read_only_room" v-show="showOptions" class="room-option account ma-0" flat>
              <v-card-text class="with-right-label">
                <div>
                  <div class="option-title">{{ $t('room_info.read_only_room') }}</div>
                  <div class="option-text">{{ $t('room_info.read_only_room_info') }}</div>
                </div>
                <v-switch v-model="readOnlyRoom"></v-switch>
              </v-card-text>
            </v-card>
          </template>

          <div class="error--text" v-if="roomCreationErrorMsg"> {{ roomCreationErrorMsg }}</div>
          <v-btn id="btn-room-create" color="black" depressed class="filled-button" @click.stop="onCreate"
            :disabled="isDisabled" ref="create">
            <div v-if="status && !enterRoomDialog" class="text-center">
              {{ status }}
              <v-progress-circular v-if="step == steps.CREATING" indeterminate color="primary"
                size="20"></v-progress-circular>
            </div>
            <span v-else>{{ $t("new_room.create") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <interactive-auth ref="interactiveAuth" />

    <input id="room-avatar-picker" ref="avatar" type="file" name="avatar" @change="handlePickedAvatar($event)"
      accept="image/*" class="d-none" />

    <input id="user-avatar-picker" ref="useravatar" type="file" name="user-avatar" @change="handlePickedUserAvatar($event)" accept="image/*" class="d-none" />

    <v-dialog v-model="enterRoomDialog" :width="$vuetify.breakpoint.smAndUp ? '50%' : '90%'">
      <v-card>
        <v-container v-if="canEditProfile" class="pa-10">
          <v-row class="align-center">
            <v-col class="py-0">
              <div class="text-start font-weight-bold">{{ $t("join.choose_name") }}</div>
              <v-select ref="avatar" :items="availableAvatars" cache-items outlined dense @change="selectAvatar"
                :value="selectedProfile">
                <template v-slot:selection>
                  <v-text-field background-color="transparent" solo flat hide-details   
                    @click.native.stop="(event) => event.target.focus()"                 
                    @focus="$event.target.select()"
                    v-model="selectedProfile.name"></v-text-field>
                </template>
                <template v-slot:item="data">
                  <v-avatar size="32">
                    <v-img :src="data.item.image" />
                  </v-avatar>
                  <div class="ms-2">{{ data.item.name }}</div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2" class="py-0">
              <v-avatar @click="showUserAvatarPicker">
                <v-img v-if="selectedProfile" :src="selectedProfile.image" />
              </v-avatar>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="py-0">
              <v-checkbox id="chk-remember-me" class="mt-0" v-model="rememberMe" @change="onRememberMe"
                :label="$t('join.remember_me')" />
            </v-col>
          </v-row>
          <v-btn color="black" depressed class="filled-button" @click.stop="onEnterRoom"
            :disabled="!selectedProfile.name">
            {{ $t("join.enter_room") }}
          </v-btn>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import util, { ROOM_TYPE_DEFAULT } from "../plugins/utils";
import InteractiveAuth from './InteractiveAuth.vue';
import rememberMeMixin from "./rememberMeMixin";
import roomTypeMixin from "./roomTypeMixin";
import RoomTypeSelector from './RoomTypeSelector.vue';

const steps = Object.freeze({
  INITIAL: 0,
  //NAME_SET: 1,
  CREATING: 2,
  CREATED: 3,
});

export default {
  name: "CreateRoom",
  components: { InteractiveAuth, RoomTypeSelector },
  mixins: [rememberMeMixin, roomTypeMixin],
  data() {
    return {
      steps,
      step: steps.INITIAL,
      roomId: null,
      roomName: "",
      roomTopic: "",
      roomAvatar: null,
      roomAvatarFile: null,
      status: "",
      joinRule: 0,
      joinRules: [
        {
          id: "public",
          text: this.$t("new_room.public_info"),
          icon: "link",
          descr: this.$t("new_room.public_description"),
        },
        {
          id: "invite",
          text: this.$t("new_room.invite_info"),
          icon: "person_add",
          descr: this.$t("new_room.invite_description"),
        },
      ],
      publicRoomLink: null,
      publicRoomLinkCopied: false,
      availableAvatars: [],
      selectedProfile: {
        id: "",
        image: "",
        name: ""
      },
      enterRoomDialog: false,
      roomNamerules: [
        v => v.length <= 50 || this.$t("new_room.room_name_limit_error_msg"),
        v => !v.includes(':') || this.$t("new_room.colon_not_allowed")
      ],
      roomNameHasError: false,
      roomCreationErrorMsg: "",
      showOptions: false,
      unencryptedRoom: false,
      readOnlyRoom: false,
      roomType: ROOM_TYPE_DEFAULT,
    };
  },

  mounted() {
    this.joinRule = this.joinRules[0].id; // Set default
    this.availableAvatars = util.getDefaultAvatars();
    this.selectAvatar(
      this.availableAvatars[
      Math.floor(Math.random() * this.availableAvatars.length)
      ]
    );
  },

  watch: {
    joinRule() {
      console.log("Join rule changed to", this.joinRule);
    },
  },
  computed: {
    isDisabled() {
      return this.status ? true : this.roomName.length === 0 || this.roomName.length > 50 || this.roomNameHasError
    },
    roomAvatarLetter() {
      if (!this.roomName) {
        return null;
      }
      return this.roomName.substring(0, 1).toUpperCase();
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    canEditProfile() {
      // If we have an account already, we can't edit profile here (need to go into profile view)
      if (this.currentUser) {
        return false;
      }
      return true;
    },
  },

  methods: {
    updateErrorState(errorState) {
      this.roomNameHasError = errorState
    },
    goBack() {
      if (this.step == steps.NAME_SET) {
        this.step = steps.INITIAL;
      } else {
        this.$navigation.pop();
      }
    },

    onCreate() {
      if (this.currentUser) {
        this.onEnterRoom();
      } else {
        this.enterRoomDialog = true;
      }
    },

    onEnterRoom() {
      this.enterRoomDialog = false;
      if (this.step == steps.CREATED) {
        this.openRoom();
      } else if (this.step == steps.INITIAL) {
        //  this.step = steps.NAME_SET;
        //} else if (this.step == steps.NAME_SET) {
        // Create room with deafult setting
        this.createRoom().then((roomId) => {
          this.roomId = roomId;
          this.openRoom(); // Open room (if id is set!)
        });
      }
    },

    openRoom() {
      if (this.roomId) {
        this.$navigation.push(
          {
            name: "Chat",
            params: { roomId: util.sanitizeRoomId(this.roomId) },
          },
          -1
        );
      }
    },

    getPublicLink() {
      this.createRoom().then((roomId) => {
        this.roomId = roomId;
        var room = null;
        if (roomId) {
          room = this.$matrix.getRoom(roomId);
        }
        if (room) {
          this.publicRoomLink = this.$router.getRoomLink(
            room.getCanonicalAlias(), roomId, room.name
          );
        }
      });
    },
    addPeople() {
      // For now, jump straight to create
      this.createRoom().then((roomId) => {
        this.roomId = roomId;
        this.$matrix.setCurrentRoomId(roomId);
        this.$navigation.push(
          {
            name: "Invite",
          },
          1
        );
      });
    },
    createRoom() {
      this.step = steps.CREATING;

      const hasUser = this.currentUser ? true : false;
      var setProfileData = false;

      var roomId;
      this.status = this.$t("new_room.status_creating");
      var createRoomOptions = {};
      if (this.joinRule == "public") {
        createRoomOptions = {
          visibility: "private", // Not listed!
          name: this.roomName,
          preset: "public_chat",
          initial_state:
            this.unencryptedRoom ? [
            {
              type: "m.room.history_visibility",
              state_key: "",
              content: {
                history_visibility: "shared"
              }
            }
            ] :
          [
            {
              type: "m.room.encryption",
              state_key: "",
              content: {
                algorithm: "m.megolm.v1.aes-sha2",
              },
            },
            {
              type: "m.room.history_visibility",
              state_key: "",
              content: {
                history_visibility: "joined"
              }
            },
          ],
        };
      } else {
        //if (this.joinRule == "invite") {
        createRoomOptions = {
          visibility: "private", // Not listed!
          name: this.roomName,
          preset: "private_chat",
          initial_state: [
            {
              type: "m.room.encryption",
              state_key: "",
              content: {
                algorithm: "m.megolm.v1.aes-sha2",
              },
            },
            {
              type: "m.room.guest_access",
              state_key: "",
              content: {
                guest_access: "forbidden",
              },
            },
            {
              type: "m.room.history_visibility",
              state_key: "",
              content: {
                history_visibility: "joined"
              }
            }
          ],
        };
      }
      if (this.roomTopic && this.roomTopic.length > 0) {
        // Add topic
        createRoomOptions.topic = this.roomTopic;
      }
      if (this.roomType != ROOM_TYPE_DEFAULT) {
        createRoomOptions.creation_content = {
          type: this.roomType
        }
      }

      return this.$matrix
        .getLoginPromise(this.$refs.interactiveAuth.registrationFlowHandler)
        .then(
          function (user) {
            if (user.is_guest && !hasUser) {
              // Newly created account, joining first room.
              // Set avatar and display name to either the randomly chosen ones, or the
              // ones the users has changed to.
              setProfileData = true;

              // Set display name and avatar directly on the matrix object.
              if (
                this.selectedProfile.name &&
                this.selectedProfile.name.length > 0
              ) {
                this.$matrix.userDisplayName = this.selectedProfile.name;
              }
            }

            if (
              !setProfileData ||
              !this.selectedProfile.name ||
              this.selectedProfile.name.length == 0
            ) {
              return Promise.resolve(user);
            } else {
              console.log(
                "CreateRoom: Set display name to: " + this.selectedProfile.name
              );
              return this.$matrix.setUserDisplayName(this.selectedProfile.name);
            }
          }.bind(this)
        )
        .then(
          function () {
            if (!setProfileData || !this.selectedProfile.image) {
              console.log("CreateRoom: No avatar change");
              return Promise.resolve("no avatar");
            } else {
              console.log("CreateRoom: Updating avatar");
              return util.setAvatar(
                this.$matrix,
                this.selectedProfile.image,
                function (progress) {
                  console.log("Progress: " + JSON.stringify(progress));
                }
              );
            }
          }.bind(this)
        )
        .then(() => {
          if (this.joinRule == "public") {
            // Promise to get a unique alias and use it in room creation options.
            //
            return util
              .getUniqueAliasForRoomName(
                this.$matrix.matrixClient,
                this.roomName,
                this.$matrix.currentUserMXDomain
              )
              .then((alias) => {
                createRoomOptions.room_alias_name = alias;
              });
          } else {
            return Promise.resolve(true);
          }
        })
        .then(() => {

          // Set power level event. Need to do that here, because we might not have the userId when the options object is created.
          const powerLevels = {};
          powerLevels[this.$matrix.currentUserId] = 100;
          let powerLevelContent = {
                users: powerLevels,
                events_default: this.readOnlyRoom ? 50 : 0
              }
            if (this.readOnlyRoom) {
             powerLevelContent.events = {
              "m.room.encrypted": 0, // NOTE! Since practically all events in encrypted rooms get sent as "m.room.encrypted" we need to set
                                      // power to 0 here. Otherwise we would not be able to send quick reactions or poll responses...
              "m.poll.response": 0,
              "org.matrix.msc3381.poll.response": 0,
              "m.reaction": 0,
              "m.room.redaction": 0,
             }; 
            }
          createRoomOptions.initial_state.push(
          {
              type: "m.room.power_levels",
              state_key: "",
              content: powerLevelContent
        });

          return this.$matrix.matrixClient
            .createRoom(createRoomOptions)
            .then(({ room_id, room_alias }) => {
              roomId = room_alias || room_id;
              if (!this.roomAvatarFile) {
                return true;
              }
              const self = this;
              return util.setRoomAvatar(
                this.$matrix.matrixClient,
                room_id,
                this.roomAvatarFile,
                (p) => {
                  if (p.total) {
                    self.status = this.$t("new_room.status_avatar_total", {
                      count: p.loaded || 0,
                      total: p.total,
                    });
                  } else {
                    self.status = this.$t("new_room.status_avatar", {
                      count: p.loaded || 0,
                    });
                  }
                }
              );
            });
        })
        .then(() => {
          this.status = "";
          this.step = steps.CREATED;
          return roomId;
        })
        .catch((error) => {
          this.status = ""
          this.roomCreationErrorMsg = (error.data && error.data.error) || error.message || error.toString();
          this.step = steps.INITIAL; // revert
          return null;
        });
    },

    /**
     * Show picker to select room avatar file
     */
    showAvatarPicker() {
      if (this.step == steps.INITIAL) {
        this.$refs.avatar.click();
      }
    },

    /**
     * Handle picked avatar
     */
    handlePickedAvatar(event) {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.roomAvatar = e.target.result;
          this.roomAvatarFile = event.target.files[0];
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },

    copyRoomLink() {
      const self = this;
      this.$copyText(this.publicRoomLink).then(
        function (ignored) {
          // Success!
          self.publicRoomLinkCopied = true;
          setInterval(() => {
            // Hide again
            self.publicRoomLinkCopied = false;
          }, 3000);
        },
        function (e) {
          // Failure!
          console.log(e);
        }
      );
    },

    selectAvatar(value) {
      this.selectedProfile = Object.assign({}, value); // Make a copy, so editing does not destroy data
    },

    showAvatarPickerList() {
      this.$refs.avatar.$refs.input.click();
    },

    /**
     * Show picker to select user avatar file
     */
     showUserAvatarPicker() {
      if (this.step == steps.INITIAL) {
        this.$refs.useravatar.click();
      }
    },

    handlePickedUserAvatar(event) {
      util.loadAvatarFromFile(event, (image) => {
        this.selectedProfile.image = image;
      });
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>