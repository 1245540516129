<template>
  <message-outgoing v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
    <div class="bubble">
      <div class="original-message" v-if="inReplyToText">
        <div class="original-message-sender">{{ inReplyToSender }}</div>
        <div
          class="original-message-text"
          v-html="linkify($sanitize(inReplyToText))"
        />
      </div>


      <div class="message">
        <ThumbnailView class="clickable" v-on:itemclick="$emit('download')" :item="{ event: event, src: null }" />
        <span class="edit-marker" v-if="event.replacingEventId()"
          >{{ $t('message.edited') }}</span
        >
      </div>
    </div>
  </message-outgoing>
</template>

<script>
import ThumbnailView from '../file_mode/ThumbnailView.vue';
import MessageOutgoing from "./MessageOutgoing.vue";

export default {
  extends: MessageOutgoing,
  components: { MessageOutgoing, ThumbnailView },
};
</script>
<style lang="scss">
@import "@/assets/css/chat.scss";
</style>