<template>
  <message-outgoing v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
    <audio controls>{{ $t("fallbacks.audio_file") }}</audio>
  </message-outgoing>
</template>

<script>
import exportedAttachmentMixin from "./exportedAttachmentMixin";
import MessageOutgoing from "../MessageOutgoing.vue";

export default {
  name: "MessageOutgoingAudioExport",
  extends: MessageOutgoing,
  components: { MessageOutgoing },
  mixins: [exportedAttachmentMixin],
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>
