<template>
  <div class="text-center">
    <v-icon size="27" class="shield">$vuetify.icons.ic_security-shield</v-icon>
    <div>{{ $t("room_welcome.no_past_messages") }}</div>
  </div>
</template>

<script>
export default {
  name: "NoHistoryRoomWelcomeHeader",
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
.shield {
  margin-bottom: 12px;  
}
</style>