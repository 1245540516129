<template>
  <div class="audio-player d-flex flex-row align-center">
    <v-progress-circular v-if="info.loading" @click.stop="pause" :value="info.loadPercent" size="24" width="2" style="margin:6px"></v-progress-circular>
    <v-btn v-else-if="info.playing" id="btn-pause" @click.stop="pause" icon><v-icon size="20">pause</v-icon></v-btn>
    <v-btn v-else id="btn-play" @click.stop="play" icon><v-icon size="20">play_arrow</v-icon></v-btn>
    <div class="play-time">
      {{ currentTime }} / {{ totalTime }}
    </div>
    <v-slider @change="seeked" :disabled="!info.url" color="currentColor" track-color="#cccccc" class="play-progress" :value="info.playPercent" min="0"
      max="100" />
  </div>
</template>

<script>
import util from "../../plugins/utils";

export default {
  props: {
    event: {
      type: Object,
      default: function () {
        return null;
      },
    },
    timelineSet: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      info: this.install(),
    };
  },
  mounted() {
    this.event.on("Event.localEventIdReplaced", this.onLocalEventIdReplaced);
  },
  beforeDestroy() {
    this.$audioPlayer.removeListener(this._uid);
    this.event.off("Event.localEventIdReplaced", this.onLocalEventIdReplaced);
  },
  computed: {
    currentTime() {
      return util.formatDuration(this.info.currentTime);
    },
    totalTime() {
      return util.formatDuration(this.info.duration);
    },
  },
  methods: {
    install() {
      return this.$audioPlayer.addListener(this._uid, this.event);
    },
    play() {
      this.$audioPlayer.play(this.event, this.timelineSet);
    },
    pause() {
      this.$audioPlayer.pause(this.event);
    },
    onPlaybackStart(item) {
      if (item != this.src && this.info.playing) {
        this.pause();
      }
    },
    seeked(percent) {
      this.$audioPlayer.seek(this.event, percent);
    },
    onLocalEventIdReplaced() {
      // This happens when we are the sending party and the message has been sent and the local echo has been updated with the new real id.
      // Since we use the event id to register with the audio player, we need to update.
      this.$audioPlayer.removeListener(this._uid);
      this.info = this.$audioPlayer.addListener(this._uid, this.event);
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>
