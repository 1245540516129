<template>
  <!-- BASE CLASS FOR OUTGOING MESSAGE -->
  <div class="messageOut">
    <div class="senderAndTime">
      <div class="time">
        {{ utils.formatTime(event.event.origin_server_ts) }}
      </div>
      <div class="status">{{ event.status }}</div>
    </div>

    <div class="op-button" ref="opbutton" v-if="!event.isRedacted() && $matrix.userCanSendMessageInCurrentRoom">
      <v-btn id="btn-show-menu" icon @click.stop="showContextMenu($refs.opbutton)">
        <v-icon>more_vert</v-icon>
      </v-btn>
    </div>
    <!-- SLOT FOR CONTENT -->
    <span ref="messageInOutRef">
      <slot></slot>
    </span>
    <v-avatar
      class="avatar"
      size="32"
      color="#ededed"
      @click.stop="ownAvatarClicked"
    >
      <img v-if="userAvatar" :src="userAvatar" />
      <span v-else class="white--text headline">{{ userAvatarLetter }}</span>
    </v-avatar>
    <QuickReactions :event="eventForReactions" :timelineSet="timelineSet" v-on="$listeners"/>
    <SeenBy :room="room" :event="event"/>
  </div>
</template>

<script>
import SeenBy from "./SeenBy.vue";
import messageMixin from "./messageMixin";
import util from "../../plugins/utils";

export default {
  mixins: [messageMixin],
  components: { SeenBy },
  mounted() {
    if(util.isMobileOrTabletBrowser() && this.$refs.messageInOutRef) {
      this.initMsgHammerJs(this.$refs.messageInOutRef);
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/css/chat.scss";
</style>