// Installing your PWA is required for periodic syncs to work
const registerPeriodicBackgroundSync = async (registration) => {
  if ('periodicSync' in registration) {
    const status = await navigator.permissions.query({
      name: 'periodic-background-sync',
    });
    if (status.state === 'granted') {
      console.log('Periodic background sync registered and granted')

      try {
        await registration.periodicSync.register('check-new-messages', {
          // minInterval is one day
          minInterval: 24 * 60 * 60 * 1000,
        });
        console.log('Periodic background sync registered!');
        console.log(registration.periodicSync.getTags())

        const tags = await registration.periodicSync.getTags();
        if (tags.length) {
          tags.forEach((tag) => {
            console.log('tag name')
            console.log(tag)
          });
        }
      } catch(e) {
        console.log(`Periodic background sync failed: ${e}`);
      }
    } else {
      console.log('Periodic background sync is not granted.');
    }
  } else {
    console.log('Periodic background sync is not supported.');
  }
}

export function registerServiceWorker(periodicSyncNewMsgReminderTxt) {
  if("serviceWorker" in navigator) {
    navigator.serviceWorker.register("./sw.js")
      .then(async registration => {
        console.log('Service Worker registered with scope:', registration.scope);
        registration.active.postMessage(periodicSyncNewMsgReminderTxt);
        await registerPeriodicBackgroundSync(registration);
      })
      .catch(error => {
        console.error('Service Worker registration failed:', error);
      });
  } else {
    console.log("No Service Worker support!");
  }
}

export async function  requestNotificationPermission() {
  if("PushManager" in window) {
    return Notification?.requestPermission().then((permission) => permission);
  } else {
    console.log("No Push API Support!");
  }
}

export function windowNotificationPermission() {
  return window?.Notification?.permission ?? 'Not_supported'
}

export function notificationCount() {
  return this.$matrix.notificationCount
}
