<template>
<div>
  <v-expand-transition>
    <v-card class="ma-3" v-show="locationLink" flat>
      <v-container>
        <slot/>
        <v-row cols="12" class="qr-container ma-3">
          <v-col cols="auto">
            <canvas
              v-longTap:250="[
                ()=> {
                  showFullScreenQR = true;
                },
                (el) => { $emit('long-tap', el); }
              ]"
              ref="roomQr"
              class="qr"
              id="room-qr"
            ></canvas>
            <slot name="share"></slot>
          </v-col>
          <v-col align-self="center" class="public-link">
            <div class="link">{{ locationLink }}</div>
          </v-col>
        </v-row>
        <v-row align="center" class="mt-0 pt-0">
          <v-col align="center" class="mt-0 pt-0">
            <v-btn
              id="btn-copy-room-link"
              :color="locationLinkCopied ? '#DEE6FF' : 'black'"
              depressed
              @click.stop="copyRoomLink"
              :class="{'filled-button' : true, 'link-copied-in-place' : locationLinkCopied}"
              >{{ $t(`room_info.${locationLinkCopied ? 'link_copied' : i18nCopyLinkKey}`) }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-expand-transition>
  <QRCodePopup :show="showFullScreenQR" :message="locationLink" :title="popupTitle" @close="showFullScreenQR = false" />
</div>
</template>

<script>
import QRCode from "qrcode";
import QRCodePopup from './QRCodePopup.vue';

export default {
  components: {
    QRCodePopup
  },
  props: {
    locationLink: {
      type: String
    },
    i18nCopyLinkKey: {
      type: String,
      default: 'copy_link'
    },
    i18nQrPopupTitleKey: {
      type: String,
      default: 'room_info.scan_code'
    }

  },
  data() {
    return {
      locationLinkCopied: false,
      showFullScreenQR: false,
    }
  },
  computed: {
    popupTitle() {
      return this.$t(this.i18nQrPopupTitleKey);
    },
  },
  methods: {
    copyRoomLink() {
      if(this.locationLinkCopied) return
      const self = this;
      this.$copyText(this.locationLink).then(
        function (ignored) {
          // Success!
          self.locationLinkCopied = true;
          setInterval(() => {
            // Hide again
            self.locationLinkCopied = false;
          }, 3000);
        },
        function (e) {
          console.log(e);
        }
      );
    },
    updateQRCode() {
      var fullUrl = this.locationLink;
      var canvas = this.$refs.roomQr;
      if (fullUrl && canvas) {
        QRCode.toCanvas(
          canvas,
          fullUrl,
          {
            type: "image/png",
            margin: 1,
            width: canvas.getBoundingClientRect().width,
          },
          function (error) {
            if (error) console.error(error);
            else console.log("success!");
          }
        );
      }
    },
  },
  watch: {
    locationLink: {
      handler() {
        this.updateQRCode();
      }
    }
  },
  mounted() {
    this.updateQRCode();
  }
}
</script>

<style lang="scss">
  .qr-container {
    background-color: white;
    border-radius: 8px;
    margin-top: 20px !important;
    .qr {
      width: 60px;
      height: 60px;
      background-color: #e0e0e0;
    }
    .public-link {
      background-color: #D6D5FC33;
      border-radius: 11px;
      .link {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        text-decoration: underline;
        color: #3d6eff;
        overflow-wrap: anywhere;
      }
    }

  }

  .link-copied-in-place .v-btn__content {
    font-family: "Inter", sans-serif !important;
    font-size: 12px !important;
    text-transform: none !important;
    color: #3d6eff;
  }
</style>